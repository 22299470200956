import React, { useEffect, useState, useCallback } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import "./DailyTimeAllocationFormModal.scss";
import { ADD_EMPLOYEE_WPS_TIME_ALLOCATION, GET_EMPLOYEE_TIME_ALL_ACTIVITY } from "../../../../utils/services/apiPath";
import { getApi, postApi } from "../../../../utils/services";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import ProTextInput from "../../Inputs/TextField";
import ProButton from "../../ProButton";
import { fieldTruePermission, roundNumber } from "../../../../utils/formHelper";
import PropTypes from "prop-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "600px",
  width: "calc(100% - 32px)",
  bgcolor: "background.paper",
  p: 4
};

export default function DailyTimeAllocationFormModal({ viewMode, show, handleClose, actionData, canEdit = true }) {
  const [timeAllocationData, setTimeAllocationData] = useState([]);
  const [approved, setApproved] = useState(viewMode);
  const { control, setValue, reset, handleSubmit, watch } = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const estimatedHours = watch("estimatedHours", 0);
  const estimatedH = Number(estimatedHours) || 0;

  const getSelectedData = useCallback(async () => {
    if (actionData) {
      const { data, error } = await getApi(`${GET_EMPLOYEE_TIME_ALL_ACTIVITY}/${actionData?.projectApprenticeMentorId}`, {
        projectApprenticeMentorId: actionData?.projectApprenticeMentorId
      });
      if (error) return errorToast(error);
      if (!data.length) {
        handleClose();
        return errorToast("No data found");
      }
      const { otjHours, estimatedHours, isValidated } = data[0];
      const addedHours = (approved || isValidated ? otjHours : estimatedHours) || 0;
      setApproved(isValidated || viewMode);
      setTimeAllocationData(
        data.map(item => {
          const otjPer = roundNumber(item.otjHoursPercentage || 0);
          return {
            ...item,
            otjHoursPercentage: otjPer,
            otjHours: roundNumber((addedHours * otjPer) / 100)
          };
        })
      );
      setValue("estimatedHours", addedHours || "");
    }
  }, [actionData]);

  const onChange = (e, name) => {
    const { value } = e.target;
    const [key, id] = name.split("_");
    if (value < 0) return;
    if (key === "otjHours" && value > estimatedH) return errorToast("OTJ Hours should not be greater than estimated hours.");
    if (key === "otjHoursPercentage" && value > 100) return errorToast("Percentage should not be greater than 100.");
    let total = 0;
    const modifiedData = timeAllocationData.map(log => {
      const updatedLog = { ...log };
      if (updatedLog.rapwpsActivityId === id) {
        if (key === "otjHoursPercentage") updatedLog.otjHours = (value * estimatedH) / 100;
        else {
          console.log("value");
          updatedLog.otjHoursPercentage = estimatedH ? roundNumber((value * 100) / estimatedH) : 0;
        }
        updatedLog[key] = value.length > 1 ? value.replace(/^0+/, "") : value;
      }
      total += Number(updatedLog.otjHours || 0);
      return updatedLog;
    });
    if (roundNumber(total) > roundNumber(estimatedH)) {
      return errorToast(
        key === "otjHours" ? "OTJ Hours should not be greater than estimated hours." : `Total percentage should not be greater than 100.`
      );
    }

    setTimeAllocationData(modifiedData);
  };

  const onClose = refresh => {
    reset();
    handleClose(refresh);
    setTimeAllocationData({});
  };

  const onSave = async () => {
    const data = timeAllocationData.map(item => ({
      projectApprenticeMentorId: actionData?.projectApprenticeMentorId,
      rapwpsActivityId: item.rapwpsActivityId,
      otjHoursPercentage: item.otjHoursPercentage || 0,
      estimatedHours: estimatedHours
    }));
    const { error } = await postApi(ADD_EMPLOYEE_WPS_TIME_ALLOCATION, data);
    if (error) return errorToast(error);
    onClose(true);
    successToast("OTJ Hours updated successfully.");
  };

  useEffect(() => {
    getSelectedData();
  }, [getSelectedData]);

  useEffect(() => {
    if (estimatedH) {
      setTimeAllocationData(timeAllocationData =>
        timeAllocationData.map(item => {
          return { ...item, otjHours: roundNumber((estimatedH * (item.otjHoursPercentage || 0)) / 100) };
        })
      );
    }
  }, [estimatedH]);

  if (!timeAllocationData.length) return null;

  const total = roundNumber(timeAllocationData.reduce((acc, curr) => acc + +curr.otjHours, 0));
  const incomplete = !estimatedH || roundNumber(total) !== roundNumber(estimatedH);

  return (
    <Modal open={show} onClose={onClose}>
      <Box sx={style}>
        <div className="proDwm-content">
          <Grid container spacing={1}>
            <Grid item xs={7}>
              <div className="proDwm-content-title">{approved ? "OTJ" : "Estimated Total Apprentice"} Hours</div>
            </Grid>
            <Grid item xs={5}>
              <ProTextInput
                size={12}
                control={control}
                name="estimatedHours"
                label="Hours"
                placeholder="Hours"
                type="number"
                disabled={approved}
                rules={{
                  required: "Estimated Apprentice Hours is required.",
                  valueAsNumber: true,
                  max: 24,
                  min: 0
                }}
                permission={fieldTruePermission}
              />
            </Grid>
          </Grid>
          {timeAllocationData.map(item => (
            <Grid container spacing={1} key={item.rapwpsActivityId}>
              <Grid item xs={7}>
                <div className="proDwm-content-title">{item.wpsTitle}</div>
              </Grid>
              <Grid item xs={5}>
                <Grid container spacing={1}>
                  <ProTextInput
                    size={6}
                    control={control}
                    name={`otjHours_${item.rapwpsActivityId}`}
                    label="Hours"
                    placeholder="Hours"
                    type="number"
                    disabled={approved}
                    fieldProps={{
                      value: item.otjHours,
                      onChange: e => onChange(e, `otjHours_${item.rapwpsActivityId}`)
                    }}
                    permission={fieldTruePermission}
                  />
                  <ProTextInput
                    size={6}
                    control={control}
                    name={`otjHoursPercentage_${item.rapwpsActivityId}`}
                    label="Percentage"
                    placeholder="Percentage"
                    type="number"
                    disabled={approved}
                    fieldProps={{
                      value: item.otjHoursPercentage,
                      onChange: e => onChange(e, `otjHoursPercentage_${item.rapwpsActivityId}`)
                    }}
                    permission={fieldTruePermission}
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
          {!!estimatedH && incomplete && (
            <Grid container>
              <Grid item xs={12}>
                <div className={`proDwm-content-warning warning`}>
                  WPS Hours Remaining: {roundNumber(estimatedH - total)}/{estimatedH}
                </div>
              </Grid>
            </Grid>
          )}
        </div>
        {!approved && (
          <div className="proDwm-footer">
            <ProButton onClick={handleSubmit(onSave)} disabled={!canEdit || incomplete} variant="contained">
              Submit
            </ProButton>
            <ProButton onClick={onClose} variant="outlined">
              Cancel
            </ProButton>
          </div>
        )}
      </Box>
    </Modal>
  );
}

DailyTimeAllocationFormModal.propTypes = {
  viewMode: PropTypes.bool,
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  actionData: PropTypes.object,
  canEdit: PropTypes.bool
};
